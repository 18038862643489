import React from 'react';
import {
  Box,
  Center,
  Heading,
  Img,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';

import fetatable from '../../img/fetatable.jpg';
import { MdCheckCircle } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Trans, t } from 'react-i18next';

export const ProductsBanner = () => {
  const { t, i18n } = useTranslation();

  return (
    <SimpleGrid minChildWidth="10vw" bg={'#C2964F'} m={0}>
      <Box>
        <Img src={fetatable}></Img>
      </Box>

      <Box h={'auto'} bg={'#2C2E35'}>
        <Center mt={'10vh'}>
          <VStack align="stretch" spacing={'20'} margin={'3vw'}>
            <Box>
              <Heading color={'#C2964F'} size="xl" fontFamily="EB Garamond">
                <Trans i18nKey={'promotion.heading'}> </Trans>
              </Heading>
            </Box>
            <Box>
              <Text
                color="white"
                fontSize="l"
                fontFamily="comfortaa"
                fontWeight="600"
              >
                <Trans i18nKey={'promotion.description'}> </Trans>
              </Text>
            </Box>

            <Box>
              <List
                spacing={3}
                fontFamily={'Noteworthy'}
                fontWeight={'300'}
                fontSize="xl"
              >
                <ListItem>
                  <Text color="white">
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Trans i18nKey={'promotion.listItem_1'}> </Trans>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="white">
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Trans i18nKey={'promotion.listItem_2'}> </Trans>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="white">
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Trans i18nKey={'promotion.listItem_3'}> </Trans>
                  </Text>
                </ListItem>

                <ListItem>
                  <Text color="white">
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Trans i18nKey={'promotion.listItem_4'}> </Trans>
                  </Text>
                </ListItem>
              </List>
            </Box>
          </VStack>
        </Center>
      </Box>
    </SimpleGrid>
  );
};
