import React from 'react';

import { ProductBanner } from './ProductBanner';
import { ProductList } from './ProductList';

export const Products = () => {
  return (
    <>
      <ProductBanner></ProductBanner>
      <ProductList></ProductList>
    </>
  );
};
