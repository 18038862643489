import { HamburgerIcon } from '@chakra-ui/icons';
import { HStack, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  Img,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logoblack.svg';

import gr from '../img/gr.png';
import en from '../img/uk.png';
import de from '../img/de.png';
import sun from '../img/sun.svg';
import { VStack } from '@chakra-ui/react';
import { useTranslation, getI18n } from 'react-i18next';
import { Trans } from 'react-i18next';

export const DrawerLeft = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeLanguage, setActiveLanguage] = useState(getI18n().language);

  useEffect(() => {
    setActiveLanguage(getI18n().language);
    console.log('asd');
  }, [getI18n().language]);

  const { t, i18n } = useTranslation();

  return (
    <>
      <HStack pos={'fixed'} left={0} margin={'2vw'} zIndex={50}>
        <IconButton
          size="2xl"
          aria-label="Open Menu"
          fontSize="3vh"
          padding={'0.6vh'}
          border={'2px solid #FFFFFF'}
          color={'white'}
          colorScheme={'blackAlpha'}
          variant={'outline'}
          onClick={onOpen}
          icon={<HamburgerIcon></HamburgerIcon>}
        />

        <Menu>
          <MenuButton>
            <Img
              src={
                activeLanguage === 'en'
                  ? en
                  : activeLanguage === 'gr'
                  ? gr
                  : activeLanguage === 'de'
                  ? de
                  : en
              }
              pl={'1vw'}
            ></Img>
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                i18n.changeLanguage('gr');
              }}
              icon={<Img src={gr} pl={'1vw'}></Img>}
            >
              Greek
            </MenuItem>
            <MenuItem
              onClick={() => i18n.changeLanguage('en')}
              icon={<Img src={en} pl={'1vw'}></Img>}
            >
              English
            </MenuItem>
            <MenuItem
              onClick={() => i18n.changeLanguage('de')}
              icon={<Img src={de} pl={'1vw'}></Img>}
            >
              German
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      {children}

      <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent bg={'#2C2E35'} borderRight={'10px solid #4582B1'}>
            <Img src={logo}></Img>

            <DrawerBody
              marginLeft="auto"
              marginRight="auto"
              fontWeight="600"
              mt={'5vh'}
            >
              <VStack>
                <Text textColor="white" as={Link} to="/">
                  <Trans i18nKey={'drawer.firstItem'}> </Trans>
                </Text>
                <br></br>

                <Text textColor="white" as={Link} to="/products">
                  <Trans i18nKey={'drawer.thirdItem'}> </Trans>
                </Text>
                <br></br>
                <Text textColor="white" as={Link} to="/communication">
                  <Trans i18nKey={'drawer.fourthItem'}> </Trans>
                </Text>
                <br></br>

                <Img pt={'4vh'} maxW={'60%'} src={sun}></Img>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
