import { Img, Box } from "@chakra-ui/react";
import React from "react";
import banner from "../../img/banner.jpg";
import banner2 from "../../img/banner2.jpg";
import Slider from "react-slick";

export const Banner = () => {
  const settings = {
    fade: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 500,
  };

  return (
    <Box m={0} p={0}>
      <Slider {...settings}>
        <Box>
          <Img src={banner}></Img>
        </Box>
        <Box>
          <Img src={banner2}></Img>
        </Box>
      </Slider>
    </Box>
  );
};
