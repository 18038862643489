import { VStack } from '@chakra-ui/react';
import { Center } from '@chakra-ui/react';
import { Img } from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react';
import { Heading } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { t, Trans } from 'react-i18next';

import goatbg from '../../img/goatbg.svg';

export const Introduction = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box p={'5vw'}>
      <Center>
        <VStack maxW={'40vw'}>
          <Box>
            <Center>
              <Heading color={'#C2964F'} size="xl" fontFamily="Noteworthy">
                <Trans i18nKey={'introduction.heading'}> </Trans>
                <Divider mt={'2vh'}></Divider>
              </Heading>
            </Center>
          </Box>

          <Text fontWeight={'800'} fontFamily="comfortaa">
            <Heading size={'4xl'} fontFamily={'Noteworthy'} color="blue.300">
              “
            </Heading>
            <Trans i18nKey={'introduction.description'}> </Trans>
            <Heading
              paddingLeft={'100%'}
              size={'4xl'}
              color="blue.300"
              fontFamily={'Noteworthy'}
            >
              ”
            </Heading>
          </Text>
          <Img src={goatbg}></Img>
        </VStack>
      </Center>
    </Box>
  );
};
