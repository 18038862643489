import React from "react";
import { Container } from "@chakra-ui/react";

import { Banner } from "./Banner";
import { Partners } from "./Partners";
import { ProductsBanner } from "./ProductsBanner";
import { Introduction } from "./Introduction";

export const Home = () => {
  return (
    <>
      <Container maxW={"100vw"} paddingLeft={0} paddingRight={0}>
        <Banner></Banner>
        <Introduction></Introduction>
        <ProductsBanner></ProductsBanner>
        <Partners></Partners>
      </Container>
    </>
  );
};
