import React from 'react';
import sklavenitis from '../../img/sklavenitis.png'; // import { useInView } from 'react-intersection-observer';
import mymarket from '../../img/mymarket.png';
import miran from '../../img/miran.png';
import metro from '../../img/metro.png';
import kantzas from '../../img/kantzas.png';
import { Box, Center, Heading, Img } from '@chakra-ui/react';
import Slider from 'react-slick';
import { Divider } from '@chakra-ui/react';
import { t, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
let settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: false,
};

export const Partners = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box padding={'5vw'}>
      <Box>
        <Center>
          <Heading color={'#C2964F'} size="xl" fontFamily="Noteworthy">
            <Trans i18nKey={'partners.heading'}></Trans>
            <Divider mt={'2vh'}></Divider>
          </Heading>
        </Center>
      </Box>

      <Box mt={'3vh'}>
        <Slider {...settings}>
          <Box>
            <Img src={sklavenitis}></Img>
          </Box>
          <Box>
            <Img src={mymarket}></Img>
          </Box>
          <Box>
            <Img src={miran}></Img>
          </Box>
          <Box>
            <Img src={kantzas}></Img>
          </Box>
          <Box>
            <Img src={metro}></Img>
          </Box>
        </Slider>
      </Box>
    </Box>
  );
};
