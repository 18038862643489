import {
  Box,
  Divider,
  Flex,
  Text,
  Heading,
  Container,
  Img,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { t, Trans } from 'react-i18next';
import feta from '../../img/feta_product.svg';
import cow from '../../img/cow_product.png';
import goat from '../../img/goat_product.svg';
import buffalo from '../../img/buffalo_product.svg';
import blackpaper from '../../img/blackpaper.jpg';

export const Product = ({
  i18n_heading,
  i18n_description,
  image_source,
  background_color,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Container
        centerContent
        maxW={{ base: '90vw', md: '70vw', lg: '40vw' }}
        pt={'20vh'}
      >
        <Flex direction={'column'}>
          <Box>
            <Heading
              maxW={{ base: '80vw', md: '60vw', lg: '15vw' }}
              bg={background_color}
              textAlign="center"
              borderRadius={'30px 0 30px 0'}
              p={'1.5vh'}
              color={'#fff'}
              size="l"
              fontFamily="Noteworthy"
            >
              <Trans i18nKey={i18n_heading}> </Trans>
            </Heading>
          </Box>

          <Box
            backgroundImage={`url(${blackpaper})`}
            p={'3vh'}
            mt={'1vh'}
            borderRadius={'0 30px 0 30px '}
            boxShadow="dark-lg"
            borderBottom={`10px solid ${background_color}`}
          >
            <Text fontWeight={'800'} fontFamily="comfortaa" color="#fff">
              <Trans i18nKey={i18n_description}> </Trans>
            </Text>
            <Divider mt={'2vh'}></Divider>
            <Box mt={'5vh'}>
              <Container centerContent maxW={'50vw'}>
                <Img
                  pt={'5vh'}
                  maxH={'30vh'}
                  src={
                    image_source === 'feta'
                      ? feta
                      : image_source === 'cow'
                      ? cow
                      : image_source === 'buffalo'
                      ? buffalo
                      : goat
                  }
                ></Img>
              </Container>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
};
