import React from 'react';
import { Box } from '@chakra-ui/react';
import { Product } from './Product';
import whitepaper from '../../img/whitepaper.jpg';
export const ProductList = () => {
  const settings = {
    feta: {
      heading: 'products.feta_heading',
      description: 'products.feta_description',
      image: 'feta',
      background_color: '#394C66',
    },
    cow_cheese: {
      heading: 'products.cow_cheese_heading',
      description: 'products.cow_cheese_description',
      image: 'cow',
      background_color: '#3E6B9A',
    },
    buffalo_cheese: {
      heading: 'products.buffalo_cheese_heading',
      description: 'products.buffalo_cheese_description',
      image: 'buffalo',
      background_color: '#C2964F',
    },
    goat_cheese: {
      heading: 'products.goat_cheese_heading',
      description: 'products.goat_cheese_description',
      image: 'goat',
      background_color: '#438ECB',
    },
  };
  return (
    <>
      <Box
        pt={'10vh'}
        pb={'30vh'}
        mt={'-3vh'}
        borderTop={'4vh solid #2A3D82'}
        backgroundImage={`url(${whitepaper})`}
        backgroundSize="auto"
      >
        <Product
          i18n_heading={settings.feta.heading}
          i18n_description={settings.feta.description}
          image_source={settings.feta.image}
          background_color={settings.feta.background_color}
        ></Product>

        <Product
          i18n_heading={settings.goat_cheese.heading}
          i18n_description={settings.goat_cheese.description}
          image_source={settings.goat_cheese.image}
          background_color={settings.goat_cheese.background_color}
        ></Product>
        <Product
          i18n_heading={settings.buffalo_cheese.heading}
          i18n_description={settings.buffalo_cheese.description}
          image_source={settings.buffalo_cheese.image}
          background_color={settings.buffalo_cheese.background_color}
        ></Product>

        <Product
          i18n_heading={settings.cow_cheese.heading}
          i18n_description={settings.cow_cheese.description}
          image_source={settings.cow_cheese.image}
          background_color={settings.cow_cheese.background_color}
        ></Product>
      </Box>
    </>
  );
};
