import React from 'react';
import { Img, Box } from '@chakra-ui/react';

import banner from '../../img/products_banner_1.jpg';

import Slider from 'react-slick';

export const ProductBanner = () => {
  const settings = {
    fade: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 500,
  };

  return (
    <Box m={0} p={0}>
      <Slider {...settings}>
        <Box>
          <Img src={banner}></Img>
        </Box>
      </Slider>
    </Box>
  );
};
