import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'gr',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      gr: {
        translation: {
          drawer: {
            firstItem: 'Αρχική',
            secondItem: 'Το Τυροκομείο μας',
            thirdItem: 'Τα Προϊόντα μας',
            fourthItem: 'Επικοινωνία',
          },
          introduction: {
            heading: 'Τα προϊόντα μας',
            description:
              'Στο τυροκομείο μας παράγουμε προϊόντα αγνά φτιαγμένα με αγάπη και μεράκι μόνο με φρέσκο γάλα Σερρών χωρίς πρόσθετα, με παραδοσιακές συνταγές αλλά και με σύγχρονα μέσα διατηρώντας την γεύση και την ποιότητα που όλοι θέλουν να έχουν στο τραπέζι τους',
          },
          partners: { heading: 'Οι συνεργάτες μας' },
          promotion: {
            heading: 'Σας παρουσιάζουμε το νέο μέλος της οικογένειας μας! ',
            description:
              'Βουβαλίσιο λευκό τυρί από φρέσκο βουβαλινό γάλα Σερρών. Το φρέσκο βουβαλινό γάλα που συλλέγουμε καθημερινά από την Κερκίνη Σερρών έχει ευεργετικές συνέπειες για τον ανθρώπινο οργανισμό για αυτό και συνίσταται ως τροφή των αδυνάτων και των ασθενών.',
            listItem_1: 'Είναι πλούσιο σε μέταλλα και βιταμίνες.',
            listItem_2:
              'Έχει χαμηλά επίπεδα χοληστερόλης και υψηλά επίπεδα ασβεστίου.',
            listItem_3:
              'Έχει μεγάλη περιεκτικότητα σε σίδηρο και φώσφορο, βιταμίνη Α και πρωτεΐνες.',
            listItem_4:
              'Περιέχει πληθώρα ιχνοστοιχείων όπως θειαμίνη και ριβοφλαβίνη και βιταμίνες όπως Β12.',
          },
          products: {
            feta_heading: 'ΦETA',
            feta_description:
              'Η φέτα μας χαρακτηρίζεται από την ήπια και ιδιαίτερη γεύση της. Το ευχάριστο και πλούσιο άρωμα της σε συνδυασμό με την ελαφρός σκληρή υφή της την κάνουν μοναδική. Παράγεται από φρέσκο πρόβειο και γίδινο (έως 30%) γάλα από αιγοπρόβατα της περιοχής μας.',
            cow_cheese_heading: 'ΑΓΕΛΑΔΙΝΟ ΛΕΥΚΟ ΤΥΡΙ',
            cow_cheese_description:
              'Το αγελαδινό μας τυρί χαρακτηρίζεται από την ήπια και ανάλαφρη γεύση του. Το διακριτικό του άρωμα και η μαλακή του ύφη θα συναρπάσει σίγουρα τους καταναλωτές που επιθυμούν κάτι διαφορετικό από τα αλλά τυριά.',
            buffalo_cheese_heading: 'ΒΟΥΒΑΛΙΣΙΟ ΛΕΥΚΟ ΤΥΡΙ',
            buffalo_cheese_description:
              'Το βουβαλίσιο μας τυρί χαρακτηρίζεται από την μοναδική βουτυράτη γεύση του. Η βελούδινη υφή και το πλούσιο άρωμα του σε συνδυασμό με τα πλούσια χαρακτηριστικά του βουβαλινού γάλακτος μας, χαρίζουν ένα γευστικό θησαυρό υψηλής διατροφικής αξίας. Θα το βρείτε σε δύο γεύσεις, την Κλασική και με Πράσινη Πιπεριά.',
            goat_cheese_heading: 'ΚΑΤΣΙΚΙΣΙΟ ΛΕΥΚΟ ΤΥΡΙ',
            goat_cheese_description:
              'Το κατσικίσιο μας τυρί χαρακτηρίζεται από τη μεστή γευση του. Το πλούσιο άρωμα που μας χαρίζει απλόχερα το γίδινο γάλα καθώς και η σκληρή του υφή δημιουργούν έναν τέλειο και άκρως γευστικό συνδυασμό.',
          },
          footer: {
            heading_1: 'Επικοινωνία',
            heading_2: 'Νομικά',
            heading_3: 'Βοήθεια',
            location: '7ο Χιλ Σερρών – Θεσσαλονίκης',
          },
        },
      },

      en: {
        translation: {
          drawer: {
            firstItem: 'Home',
            secondItem: 'Our Cheese Factory',
            thirdItem: 'Products ',
            fourthItem: 'Contact',
          },
          introduction: {
            heading: 'Our Products',
            description:
              'In our cheese factory we produce pure products made with love and passion only with fresh milk from Serres without additives, with traditional recipes but also with modern means, preserving the taste and quality that everyone wants to have on their table',
          },
          partners: { heading: 'Our Partners' },
          products: {
            feta_heading: 'FETA CHEESE',
            feta_description:
              'Our feta cheese is characterized by its mild and special taste. Its pleasant and rich aroma combined with its slightly hard texture that makes it one of a kind. It is produced from fresh sheep and goat (up to 30%) milk from sheep and goats in our region.',
            cow_cheese_heading: 'WHITE COW CHEESE',
            cow_cheese_description: `Our cow's cheese is characterized by its mild and light taste. Its distinctive aroma and soft texture will surely fascinate consumers who want something different from other cheeses.`,
            buffalo_cheese_heading: 'BUFFALO WHITE CHEESE',
            buffalo_cheese_description:
              'Our buffalo cheese is characterized by its unique buttery taste. Its velvety texture and rich aroma, combined with the rich characteristics of our buffalo milk, give a tasty treasure of high nutritional value. You will find it in two flavors, Classic and Green Pepper.',
            goat_cheese_heading: 'GOAT WHITE CHEESE',
            goat_cheese_description: `Our goat cheese is characterized by its rich taste. The rich aroma that goat's milk generously gives us as well as its hard texture create a perfect and highly tasty combination.`,
          },
          promotion: {
            heading: 'Introducing the newest member of our family!',
            description:
              'Buffalo white cheese from fresh buffalo milk from Serres. The fresh buffalo milk that we collect daily from Kerkini Serron has beneficial effects on the human body for it and is recommended as food for the weak and sick.',
            listItem_1: 'Rich in minerals and vitamins',
            listItem_2:
              'It has low cholesterol levels and high calcium levels.',
            listItem_3:
              'It has a high content of iron and phosphorus, vitamin A and proteins.',
            listItem_4:
              'It contains an abundance of trace elements such as thiamine and riboflavin and vitamins such as B12.',
          },
          footer: {
            heading_1: 'Contact',
            heading_2: 'Legal',
            heading_3: 'Help',
            location: '7th km Serres-Thessaloniki, Greece',
          },
        },
      },
      de: {
        translation: {
          drawer: {
            firstItem: 'Startseite',
            secondItem: 'Unsere Käserei',
            thirdItem: 'Produkte',
            fourthItem: 'Kontakt',
          },
          introduction: {
            heading: 'Unsere Produkte',
            description:
              'In unserer Käserei produzieren wir reine Produkte, die mit Liebe und Leidenschaft nur mit frischer Milch aus Serres ohne Zusatzstoffe, mit traditionellen Rezepten, aber auch mit modernen Mitteln hergestellt werden, um den Geschmack und die Qualität zu bewahren, die jeder auf seinem Tisch haben möchte',
          },
          partners: { heading: 'Unsere Partner' },
          products: {
            feta_heading: 'FETA KÄSE',
            feta_description:
              'Unser Schafskäse zeichnet sich durch seinen milden und besonderen Geschmack aus. Sein angenehmes und reiches Aroma in Kombination mit seiner leicht harten Textur machen es einzigartig. Es wird aus frischer Schaf- und Ziegenmilch (bis 30%) von Schafen und Ziegen aus unserer Region hergestellt.',
            cow_cheese_heading: 'WEISSER KUHKÄSE',
            cow_cheese_description:
              'Unser Kuhkäse zeichnet sich durch seinen milden und leichten Geschmack aus. Sein unverwechselbares Aroma und seine weiche Textur werden sicherlich Verbraucher faszinieren, die sich von anderen Käsesorten unterscheiden möchten.',
            buffalo_cheese_heading: 'BÜFFELWEIßER KÄSE',
            buffalo_cheese_description:
              'Unser Büffelkäse zeichnet sich durch seinen einzigartigen Buttergeschmack aus. Seine samtige Textur und sein reiches Aroma, kombiniert mit den reichhaltigen Eigenschaften unserer Büffelmilch, ergeben einen schmackhaften Schatz von hohem Nährwert. Sie finden es in zwei Geschmacksrichtungen, Classic und Green Pepper.',
            goat_cheese_heading: 'ZIEGENWEIßER KÄSE',
            goat_cheese_description:
              'Unser Ziegenkäse zeichnet sich durch seinen reichen Geschmack aus. Das reiche Aroma, das uns Ziegenmilch großzügig verleiht, sowie ihre harte Textur schaffen eine perfekte und äußerst schmackhafte Kombination.',
          },
          promotion: {
            heading: 'Wir stellen das neueste Mitglied unserer Familie vor!',
            description:
              'Büffelweißkäse aus frischer Büffelmilch aus Serres. Die frische Büffelmilch, die wir täglich von Kerkini Serron sammeln, hat wohltuende Wirkungen auf den menschlichen Körper und wird als Nahrung für Schwache und Kranke empfohlen',
            listItem_1: 'Es ist reich an Mineralien und Vitaminen.',
            listItem_2:
              'Es hat einen niedrigen Cholesterinspiegel und einen hohen Kalziumspiegel.',
            listItem_3:
              'Es hat einen hohen Gehalt an Eisen und Phosphor, Vitamin A und Proteinen.',
            listItem_4:
              'Es enthält eine Fülle von Spurenelementen wie Thiamin und Riboflavin und Vitamine wie B12.',
          },
          footer: {
            heading_1: 'Kontakt',
            heading_2: 'Gesetzlich',
            heading_3: 'Hilfe',
            location: '7. km Serres-Thessaloniki, Griechenland',
          },
        },
      },
    },
  });

export default i18n;
