import './App.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home } from './components/Landing/Home';
import { DrawerLeft } from './components/Drawer';
import { Products } from './components/Products/Products';

import { Footer } from './components/Footer';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({ config });

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <DrawerLeft>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/products" component={Products}></Route>
          </Switch>
        </DrawerLeft>
      </Router>
      <Footer></Footer>
    </ChakraProvider>
  );
}

export default App;
