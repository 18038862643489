import React from 'react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';

import {
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaTwitter,
} from 'react-icons/fa';
import { t, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  let date = new Date();
  return (
    <Box bgColor={'#2C2E35'} w={'100%'} mt={'0'} h={'50vh'} paddingTop={'5vh'}>
      <Container maxW={'50vw'}>
        <SimpleGrid columns={3} spacingX="40px">
          <Box
            bg="#78C3E7"
            borderRadius={'2%'}
            height="33vh"
            padding={'2vh 0 0 2vh'}
          >
            <Heading size="md" color="black">
              <Trans i18nKey={'footer.heading_1'}></Trans>
            </Heading>
            <Text color="#2C2E35" mt={'8%'}>
              <PhoneIcon color="#2C2E35" /> (+30) 23217 02176
            </Text>
            <Text color="#2C2E35" mt={'5%'}>
              <EmailIcon color="#2C2E35" /> takalavouna@gmail.com
            </Text>
            <Text color="#2C2E35" mt={'5%'}>
              <Icon color="#2C2E35" as={FaLocationArrow} />
              <Trans i18nKey={'footer.location'}></Trans>
            </Text>
          </Box>

          <Box
            bg="#78C3E7"
            borderRadius={'2%'}
            height="33vh"
            padding={'2vh 0 0 2vh'}
          >
            <Heading size="md" color="black">
              <Trans i18nKey={'footer.heading_2'}></Trans>
            </Heading>

            <Text color="#2C2E35" mt={'5%'}>
              Πολιτική Απορρίτου
            </Text>
            <Text color="#2C2E35" mt={'1%'}>
              Πολιτική Cookies
            </Text>
            {/* 
          <Text color="#2C2E35" mt={'1%'}>
            ΕΣΠΑ ΕΠΑvEK
          </Text> */}

            {/* <Img mt={'25%'} src={espa} w={'5vw'}></Img> */}
          </Box>
          <Box
            bg="#78C3E7"
            borderRadius={'2%'}
            height="33vh"
            padding={'2vh 0 0 2vh'}
          >
            <Heading size="md" color="black">
              <Trans i18nKey={'footer.heading_3'}></Trans>
            </Heading>
            <Text color="#2C2E35" mt={'5%'}>
              Υποστήριξη πελατών
            </Text>

            <Text color="#2C2E35" mt={'1%'}>
              FAQs
            </Text>
            <Text color="#2C2E35" mt={'1%'}>
              Sitemap
            </Text>
          </Box>
        </SimpleGrid>

        <Flex>
          <Icon
            pos={'relative'}
            h={'3vh'}
            w={'3vh'}
            color="#2C2E35"
            margin={'2vh'}
            _hover={{ color: 'blue.700' }}
            as={FaFacebook}
          ></Icon>
          <Icon
            pos={'relative'}
            h={'3vh'}
            w={'3vh'}
            _hover={{ color: '#833AB4' }}
            color="#2C2E35"
            margin={'2vh'}
            as={FaInstagram}
          ></Icon>
          <Icon
            pos={'relative'}
            h={'3vh'}
            w={'3vh'}
            color="#2C2E35"
            _hover={{ color: 'blue.500' }}
            margin={'2vh'}
            as={FaTwitter}
          ></Icon>
          <Spacer></Spacer>

          <Text color="#fff" mt={'2vh'} size="sm">
            {date.getFullYear()} © TaKalaVouna
          </Text>
        </Flex>
      </Container>
    </Box>
  );
};
